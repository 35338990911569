import React, { Fragment, useEffect, useState, useRef } from "react";
import { navigate, useLocation } from "@reach/router";
import { mapValues } from "lodash";
import * as fields from "./fields";
import { Row, Col, Container } from "@kogk/common";
import { Input, Checkbox, Spinner, RadioButton } from "@cmp/site";
import cn from "classnames";
import { Formik, Form, Field, useFormikContext } from "formik";
import { formatCurrency } from "@src/utils";
import styles from "./DonationForm.module.scss";

const DonationForm = ({
  onSubmit,
  error,
  amount,
  monthly,
  isSubmitting,
  termsUrl,
  campaign,
}) => {
  const [visiblePayment, setVisiblePayment] = useState(fields.KREDITCARD);
  const [html3ds, setHtml3ds] = useState("");
  const rendered = (error, values) => {
    if (
      error?.message?.startsWith(
        "Þú ert nú þegar skráð(ur) fyrir mánaðarlegum greiðslum."
      )
    ) {
      const navigations = {
        mumin: "/mumin-breytt-skraning",
        tiers: "/askriftaleidir-breytt-skraning",
        BTP: "/heimsforeldrar-breytt-skraning",
      };
      navigate(navigations[campaign], {
        state: { formValues: values },
      });
    } else {
      return <div className={cn(styles.error, "mt-2")}>{error.message}</div>;
    }
  };
  const submitHandler = async (values) => {
    setHtml3ds("");
    const data = await onSubmit(mapValues(values, fields.cleanField));
    if (data.html) {
      setHtml3ds(data.html);
    } else if (data.url) {
      window.location = data.url;
    }
  };

  return (
    <Fragment>
      {html3ds ? (
        <Secure3DS html={html3ds} />
      ) : (
        <Formik
          initialValues={fields.initialValues()}
          validate={(values) =>
            fields.validateAll(values, visiblePayment, monthly)
          }
          onSubmit={submitHandler}
        >
          {({ values, handleChange, handleBlur }) => {
            return (
              <Form>
                <div className="eyebrows eyebrows--black">
                  Upplýsingar um þig
                </div>
                <MainForm
                  values={values}
                  visiblePayment={visiblePayment}
                  setVisiblePayment={setVisiblePayment}
                  termsUrl={termsUrl}
                />
                <button
                  type="submit"
                  className={cn(styles.submit, "mb-4 mt-4 button--normal")}
                  disabled={isSubmitting}
                >
                  {isSubmitting ? (
                    <Spinner />
                  ) : (
                    `STYRKJA UNICEF ${
                      monthly ? "MÁNAÐARLEGA" : ""
                    } UM ${formatCurrency(amount)} KR.`
                  )}
                </button>

                {error && rendered(error, values)}
              </Form>
            );
          }}
        </Formik>
      )}
    </Fragment>
  );
};

const MainForm = ({
  handleChange,
  handleBlur,
  termsUrl,
  visiblePayment,
  setVisiblePayment,
}) => {
  const { pathname } = useLocation();
  const isRecurring = [
    "/gerast-heimsforeldri",
    "/mumin",
    "/askriftaleidir",
    "/mumin-skraning",
  ].includes(pathname);
  const { values, setValues } = useFormikContext();
  if (typeof window === "undefined") return <></>;
  const tier = new URLSearchParams(window.location.search).get("tier");

  useEffect(() => {
    // reset bank info if switching to kreditcard
    if (visiblePayment === fields.KREDITCARD) {
      setValues({ ...values, bankCode: "", bankAccGroup: "", bankAccount: "" });
    }
    if (visiblePayment === fields.BANK_PAYMENT) {
      setValues({ ...values, cardNumber: "", cardExpiry: "", cardCvc: "" });
    }
  }, [visiblePayment]);
  return (
    <>
      {tier !== null ? <TiersForm tier={tier} /> : <BaseForm />}
      <div className={cn(styles.row, "pb-4")}>
        <Field component={TermsField} name="terms" termsUrl={termsUrl} />
      </div>
      {isRecurring ? (
        <>
          <Field
            component={RadioButton}
            className="d-block mb-3"
            checked={visiblePayment === fields.KREDITCARD}
            onClick={() => setVisiblePayment(fields.KREDITCARD)}
            value={fields.KREDITCARD}
            name="payment"
          >
            Greiða með kreditkorti
          </Field>
          {tier === null && (
            <Field
              component={RadioButton}
              checked={visiblePayment === fields.BANK_PAYMENT}
              onClick={() => setVisiblePayment(fields.BANK_PAYMENT)}
              value={fields.BANK_PAYMENT}
              name="payment"
            >
              Greiða með beingreiðslu*{" "}
              <p className={styles.info}>
                *færslugjöld bætast ofan á framlag (130 kr.)
              </p>
            </Field>
          )}
        </>
      ) : null}
      {visiblePayment === fields.KREDITCARD && isRecurring ? (
        <CreditCardSection />
      ) : null}
      {visiblePayment === "bankpayment" ? <BankAccountSection /> : null}
    </>
  );
};

const InputField = ({ field, form, ...props }) => {
  const error = form.touched[field.name] && form.errors[field.name];

  return (
    <div className={styles.row}>
      <Input {...field} {...props} hasError={Boolean(error)} />
      {Boolean(error) && <div className={styles.error}>{error}</div>}
    </div>
  );
};

const TermsField = ({ field, form, termsUrl, ...props }) => {
  return (
    <div>
      <Checkbox {...field} {...props}>
        Ég hef kynnt mér og samþykki
      </Checkbox>{" "}
      <a
        href={termsUrl || "/skilmalar"}
        target="_blank"
        className="link link--standard"
      >
        skilmála UNICEF
      </a>
      {Boolean(form.touched.terms && form.errors.terms) && (
        <div className={cn("px-0", styles.error)}>{form.errors.terms}</div>
      )}
    </div>
  );
};

const CreditCardSection = ({ values }) => {
  const { setFieldValue } = useFormikContext();
  const handleExpiryChange = (event) => {
    let { value } = event.target;
    if (value.length > 5) return;
    if (value.length === 2 && !value.includes("/")) {
      value += "/";
    } else if (value.endsWith("/")) {
      value = value.slice(0, -1);
    }
    setFieldValue("cardExpiry", value);
  };
  return (
    <Container className="px-0">
      <Row>
        <Col col={12}>
          <Field component={InputField} name="cardNumber" label="Kortanúmer" />
        </Col>
      </Row>
      <Row>
        <Col col={6}>
          <Field
            component={InputField}
            name="cardExpiry"
            label="Gildistími"
            onChange={handleExpiryChange}
          />
        </Col>
        <Col col={6}>
          <Field component={InputField} name="cardCvc" label="CVC" />
        </Col>
      </Row>
    </Container>
  );
};

const BankAccountSection = ({ values, isSubmitting }) => {
  return (
    <Container className="px-0">
      <Row>
        <Col col={6}>
          <Field
            component={InputField}
            name="bankCode"
            label="Banki"
            maxLength={4}
          />
        </Col>
        <Col col={6}>
          <Field
            component={InputField}
            name="bankAccGroup"
            label="Höfuðbók"
            maxLength={2}
          />
        </Col>
      </Row>
      <Row>
        <Col col={12}>
          <Field
            component={InputField}
            name="bankAccount"
            label="Reikningsnúmer"
            maxLength={6}
          />
        </Col>
      </Row>
    </Container>
  );
};

const Secure3DS = ({ html }) => {
  const formContainerRef = useRef(null);
  useEffect(() => {
    // Submit the form programmatically after it has been injected into the DOM
    const form = formContainerRef.current?.querySelector("#webform0");
    if (form) {
      form.submit();
    }
  }, []);

  return (
    <div
      ref={formContainerRef}
      dangerouslySetInnerHTML={{
        __html: html,
      }}
    ></div>
  );
};

const BaseForm = ({ values, isSubmitting }) => (
  <>
    <Field component={InputField} name="ssn" label="Kennitala" />
    <Field component={InputField} name="name" label="Nafn" />
    <Field component={InputField} name="phone" label="Farsími" />
    <Field component={InputField} name="email" label="Netfang" />
  </>
);
const TiersForm = ({ tier }) => (
  <>
    <Field component={InputField} type="hidden" name="tier" value={tier} />
    <Field component={InputField} name="name" label="Nafn fyrirtækis" />
    <Field component={InputField} name="ssn" label="Kennitala fyrirtækis" />
    <Field
      component={InputField}
      name="contact"
      label="Tengiliður fyrirtækis"
    />
    <Field component={InputField} name="email" label="Netfang tengiliðs" />
    <Field component={InputField} name="phone" label="Farsími tengiliðs" />
  </>
);

export default DonationForm;
